<template>
  <div class="payments" :class="{ small }">
    <div
      ref="paymentsInfo"
      class="d-flex align-center payments__info"
      :class="{
        'justify-center': !small,
        'justify-start': small,
      }"
    >
      <div class="mt-0-4 mb-0-4" title="Visa">
        <icon :name="small ? 'visa-small' : 'visa'" />
        <span class="visually-hidden">Visa</span>
      </div>
      <div class="mt-0-4 mb-0-4" title="Mastercard">
        <icon :name="small ? 'mastercard-small' : 'mastercard'" />
        <span class="visually-hidden">Mastercard</span>
      </div>
      <div class="mt-0-4 mb-0-4" title="Carte bancaire">
        <icon :name="small ? 'carte-bancaire-small' : 'carte-bancaire'" />
        <span class="visually-hidden">Carte bancaire</span>
      </div>
      <div class="mt-0-4 mb-0-4" title="American express">
        <icon :name="small ? 'amex-small' : 'american-express'" />
        <span class="visually-hidden">American express</span>
      </div>
      <div v-if="payments.paypal" class="mt-0-4 mb-0-4" title="Paypal">
        <icon :name="small ? 'paypal-small' : 'paypal'" />
        <span class="visually-hidden">Paypal</span>
      </div>
      <div v-if="payments.alma" class="mt-0-4 mb-0-4" title="Alma">
        <icon :name="small ? 'alma-small' : 'alma'" />
        <span class="visually-hidden">Alma</span>
      </div>
      <div v-if="payments.sofort" class="mt-0-4 mb-0-4" title="Sofort">
        <icon :name="small ? 'sofort-small' : 'sofort'" />
        <span class="visually-hidden">Sofort</span>
      </div>
      <div v-if="payments.bancontact" class="mt-0-4 mb-0-4" title="Bancontact">
        <icon :name="small ? 'bancontact-small' : 'bancontact'" />
        <span class="visually-hidden">Bancontact</span>
      </div>
      <div v-if="payments.ideal" class="mt-0-4 mb-0-4" title="Ideal">
        <icon :name="small ? 'ideal-small' : 'ideal'" />
        <span class="visually-hidden">I-Deal</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PAYMENTS_BY_COUNTRIES } from '@/const'

import Icon from '@/components/icons/Icon'

export default {
  name: 'PaymentsAvailableList',
  components: { Icon },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ detectedCountry: 'behavior/detectedCountry' }),
    payments() {
      return {
        alma:
          PAYMENTS_BY_COUNTRIES[this.detectedCountry] &&
          PAYMENTS_BY_COUNTRIES[this.detectedCountry].includes('alma'),
        sofort:
          PAYMENTS_BY_COUNTRIES[this.detectedCountry] &&
          PAYMENTS_BY_COUNTRIES[this.detectedCountry].includes('sofort'),
        paypal:
          PAYMENTS_BY_COUNTRIES[this.detectedCountry] &&
          PAYMENTS_BY_COUNTRIES[this.detectedCountry].includes('paypal'),
        ideal:
          PAYMENTS_BY_COUNTRIES[this.detectedCountry] &&
          PAYMENTS_BY_COUNTRIES[this.detectedCountry].includes('ideal'),
        bancontact:
          PAYMENTS_BY_COUNTRIES[this.detectedCountry] &&
          PAYMENTS_BY_COUNTRIES[this.detectedCountry].includes('bancontact'),
      }
    },
  },
  mounted() {
    this.updateDetectedCountry()
    // this.loadTooltip()
  },
  methods: {
    ...mapActions({ updateDetectedCountry: 'behavior/updateDetectedCountry' }),
    // async loadTooltip() {
    //   const tippy = await import('tippy.js' /* webpackChunkName: 'tippy' */)
    //   await import(
    //     '@/scss/09-libs/_06-tippy.scss' /* webpackChunkName: 'tippy_css' */
    //   )
    //   await import(
    //     'tippy.js/animations/scale.css' /* webpackChunkName: 'tippy_animation_scale_css' */
    //   )

    //   tippy.default(this.$refs.paymentsInfo, {
    //     theme: 'dark',
    //     placement: 'top',
    //     animation: 'scale',
    //     content: this.$t('footer.payments.info'),
    //     arrow: true,
    //   })
    // },
  },
}
</script>

<style lang="scss">
.payments {
  &__info {
    cursor: help;
    gap: calc(var(--spacing) * 0.6);

    @include mq($from: tablet) {
      gap: calc(var(--spacing) * 2);
    }
  }

  .icon {
    display: block;
    max-width: 100%;

    @include mq($until: tablet) {
      width: rem(38px);
      height: rem(24px);

      &--paypal {
        width: rem(90px);
      }
    }

    @include mq($from: tablet) {
      width: rem(51px);
      height: rem(32px);

      &--alma {
        width: rem(60px);
      }

      &--paypal {
        width: rem(120px);
      }

      &--giropay {
        width: rem(72px);
      }

      &--sofort {
        width: rem(55px);
      }

      &--ideal {
        width: rem(40px);
      }
    }
  }

  .rich-text {
    margin: 0;

    .tooltip::after {
      left: rem(-2px);
      top: rem(-10px);
    }
  }

  &.small {
    .payments__info {
      cursor: default;

      @include mq($from: tablet) {
        gap: calc(var(--spacing));
      }
    }

    .icon {
      width: rem(38px);
      height: rem(24px);
    }
  }
}
</style>
