<template>
  <div v-if="skeleton">
    <div class="footer__newsletter">
      <div class="container"></div>
    </div>
  </div>
  <div
    v-else-if="footer"
    id="footer"
    v-waypoint="{
      active: !prefooterLinksLoaded,
      callback: onWaypoint,
      options: intersectionOptions,
    }"
    class="footer"
  >
    <div id="newsletter" class="footer__newsletter text-center pt-4 pb-4">
      <div class="container">
        <div class="d-md-flex flex-direction-column align-center">
          <prismic-title
            ref="newsletterTitle"
            :title="footer.newsletter_title"
            class="footer__newsletter__title mb-0-4"
          />

          <rich-text
            v-if="footer.newsletter_text.length > 0"
            ref="newsletterText"
            :content="footer.newsletter_text"
            class="text-md-justify mb-1 pb-0-4"
          />

          <form-klaviyo-email id="footer" ref="newsletterForm" />
        </div>
      </div>
    </div>

    <div id="prefooter-links" class="footer__prefooter-links">
      <div v-if="prefooterLinksLoaded" class="content d-flex">
        <pre-footer-link
          v-for="(item, index) in loadedPreFooterLinks"
          :key="`prefooter-link-${index}`"
          :link="item"
          class="flex-grow-1"
        />
      </div>
      <div v-else class="content d-flex">
        <div class="skeleton flex-grow-1"></div>
        <div class="skeleton flex-grow-1"></div>
      </div>
    </div>

    <div class="footer__reinsurance">
      <div class="container d-md-flex justify-center">
        <prismic-link
          v-for="(item, index) in footer.reinsurance"
          :key="'reinsurance' + index"
          :link="item.link"
          class="h4 link--underline d-block mb-0"
        >
          <icon-star class="icon--before" />
          <span>
            {{ item.label }}
          </span>
          <icon-star class="icon--after" />
        </prismic-link>
      </div>
    </div>

    <div class="footer__payments pt-1 pb-1 pl-1 pr-1">
      <payments-available-list />
    </div>

    <footer class="text-center pb-2">
      <div class="container">
        <div class="row-md justify-space-between">
          <div class="col-md-6 logo pt-2 pt-md-5">
            <icon-logo-footer />
          </div>

          <nav class="col-md-3 footer__nav pt-3 pt-md-6">
            <prismic-link
              v-for="(item, index) in leftLinks"
              :key="`left-link-${index}`"
              :link="item.link"
              class="link--underline text-secondary text-uppercase"
            >
              <span>{{ item.label }}</span>
            </prismic-link>
          </nav>

          <nav class="col-md-3 footer__nav pt-md-6">
            <prismic-link
              v-for="(item, index) in rightLinks"
              :key="`right-link-${index}`"
              :link="item.link"
              class="link--underline text-secondary text-uppercase"
            >
              <span>{{ item.label }}</span>
            </prismic-link>

            <div class="footer__socials d-flex justify-center mt-2">
              <prismic-link
                v-for="(item, index) in socialNetworks"
                :key="`social-networks-${index}`"
                :link="item.link"
              >
                <img
                  :src="require(`~/assets/img/${getSocialImage(item)}.svg`)"
                  :alt="getSocialImage(item)"
                />
              </prismic-link>
            </div>
          </nav>
        </div>

        <nuxt-link
          v-if="bCorpLink"
          :to="$contextPrismicPath(bCorpLink)"
          class="d-flex align-center justify-center flex-direction-column mt-3"
        >
          <span class="d-block text-secondary w-600 pb-0-2">
            {{ $t('b_corp_certified') }}
          </span>
          <icon name="b-corp" />
        </nuxt-link>

        <p class="text-caption mt-2 mb-0">
          {{ $t('copyright', { year: $d(new Date(), 'year') }) }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import * as prismic from '@prismicio/client'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

import PrismicLink from '@/components/prismic/PrismicLink'
import PrismicTitle from '@/components/Title/PrismicTitle'
import RichText from '@/components/RichText/RichText'

import FormKlaviyoEmail from '@/components/form/klaviyo/Email'
import IconStar from '@/components/icons/Star'
import IconLogoFooter from '@/components/icons/LogoFooter'
import Icon from '@/components/icons/Icon'
import PreFooterLink from '@/components/footer/PreFooterLink'
import PaymentsAvailableList from '@/components/payments/availableList'

import { PRISMIC_CONTEXT } from '@/const'

export default {
  name: 'FooterDefault',
  components: {
    PrismicLink,
    PrismicTitle,
    RichText,
    FormKlaviyoEmail,
    IconStar,
    IconLogoFooter,
    Icon,
    PreFooterLink,
    PaymentsAvailableList,
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    footer: { type: Object, required: true },
    highlightedProductFromPayload: { type: Object, default: null },
    nextHighlightedProductFromPayload: { type: Object, default: null },
    skeleton: { type: Boolean, default: false },
  },
  data: () => ({
    prefooterLinksLoaded: false,
    intersectionOptions: {
      root: null,
      rootMargin: '250px 0px 0px 0px',
      threshold: [0, 1],
    },
    links: {},
    highlightedProduct: null,
    nextHighlightedProduct: null,
  }),
  computed: {
    preFooterLinks() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.footer.pre_footer_links_f
      }

      return this.footer.pre_footer_links_h
    },
    loadedPreFooterLinks() {
      if (this.prefooterLinksLoaded) {
        return this.preFooterLinks
          .map((link) => {
            if (link.link_type === 'home') {
              if (this.highlightedProduct) {
                return {
                  ...link,
                  image:
                    this.highlightedProduct.data.image.prefooter ||
                    this.highlightedProduct.data.image,
                  imageCdn:
                    this.highlightedProduct.data.image_footer_cdn ||
                    this.highlightedProduct.data.image_cdn,
                  startdate: this.highlightedProduct.data.enddate,
                }
              }
            } else if (link.link_type === 'agenda') {
              if (this.nextHighlightedProduct) {
                return {
                  ...link,
                  image:
                    this.nextHighlightedProduct.data.image.prefooter ||
                    this.nextHighlightedProduct.data.image,
                  imageCdn:
                    this.nextHighlightedProduct.data.image_footer_cdn ||
                    this.nextHighlightedProduct.data.image_cdn,
                }
              }
            } else if (this.links[link.to.uid]) {
              return {
                ...link,
                image:
                  this.links[link.to.uid].data.image.footer ||
                  this.links[link.to.uid].data.image,
                imageCdn:
                  this.links[link.to.uid].data.image_footer_cdn ||
                  this.links[link.to.uid].data.image_cdn,
              }
            }

            return null
          })
          .filter((link) => link !== null)
          .filter((link) => {
            return this.$contextPrismicPath(link.to) !== this.$route.path
          })
          .slice(0, 2)
      }

      return []
    },
    leftLinks() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.footer.left_links_f
      }

      return this.footer.left_links_h
    },
    rightLinks() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.footer.right_links_f
      }

      return this.footer.right_links_h
    },
    socialNetworks() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.footer.social_networks_f
      }

      return this.footer.social_networks_h
    },
    bCorpLink() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.footer.b_corp_link_f
      }

      return this.footer.b_corp_link_h
    },
  },
  watch: {
    '$route.params.context': {
      handler() {
        this.prefooterLinksLoaded = false

        this.highlightedProduct = null
        this.nextHighlightedProduct = null

        this.preparePreFooterLinks()
      },
    },
  },
  mounted() {
    this.adaptSizes()
  },
  methods: {
    onWaypoint(waypoint) {
      if (waypoint.going === this.$waypointMap.GOING_IN) {
        this.preparePreFooterLinks()
      }
    },
    getSocialImage(item) {
      switch (item.type) {
        case 'instagram':
          return 'instagram'
        case 'tiktok':
          return 'tiktok'
        case 'youtube':
          return 'youtube'
        case 'facebook':
        default:
          return 'facebook'
      }
    },
    adaptSizes() {
      if (this.$refs.newsletterTitle) {
        if (this.$vssWidth >= 769) {
          const w = this.$refs.newsletterTitle.$el.offsetWidth

          if (this.$refs.newsletterText) {
            this.$refs.newsletterText.$el.style.maxWidth = `${w}px`
          }

          if (this.$refs.newsletterForm) {
            this.$refs.newsletterForm.$el.style.maxWidth = `${w}px`
          }
        } else {
          if (this.$refs.newsletterText) {
            this.$refs.newsletterText.$el.style.maxWidth = ``
          }

          if (this.$refs.newsletterForm) {
            this.$refs.newsletterForm.$el.style.maxWidth = ``
          }
        }
      }
    },
    preparePreFooterLinks() {
      const pp = []

      for (const link of this.preFooterLinks) {
        if (link.link_type === 'home') {
          pp.push(this.loadTheHighlightedProduct())
        } else if (link.link_type === 'agenda') {
          pp.push(this.loadTheNextHighlightedProduct())
        } else {
          pp.push(this.loadContentImageFromLink(link.to))
        }
      }

      Promise.all(pp).then(() => {
        this.prefooterLinksLoaded = true
      })
    },
    async loadTheHighlightedProduct() {
      this.highlightedProduct = null

      if (
        this.highlightedProductFromPayload &&
        this.highlightedProductFromPayload[this.$route.params.context]
      ) {
        this.highlightedProduct =
          this.highlightedProductFromPayload[this.$route.params.context]
        return
      }
      const highlightedProduct = await this.$prismic.client.get({
        filters: [
          prismic.filter.at('my.product.on_home', true),
          prismic.filter.any('my.product.context', [
            'Tous',
            this.$route.params.context &&
            PRISMIC_CONTEXT[this.$route.params.context]
              ? PRISMIC_CONTEXT[this.$route.params.context]
              : PRISMIC_CONTEXT.h,
          ]),
        ],
        graphQuery: `{
            product {
              product_name
              image
              image_cdn
              enddate
            }
          }`,
        pageSize: 1,
        page: 1,
        lang: this.$i18n.locale,
        orderings: [
          {
            field: 'document.last_publication_date',
            direction: 'desc',
          },
        ],
      })

      if (highlightedProduct.results.length > 0) {
        this.highlightedProduct = highlightedProduct.results[0]
      }
    },
    async loadTheNextHighlightedProduct() {
      this.nextHighlightedProduct = null

      if (
        this.nextHighlightedProductFromPayload &&
        this.nextHighlightedProductFromPayload[this.$route.params.context]
      ) {
        this.nextHighlightedProduct =
          this.nextHighlightedProductFromPayload[this.$route.params.context]
        return
      }

      const nextHighlightedProduct = await this.$prismic.client.get({
        filters: [
          prismic.filter.at('my.product.visible', true),
          prismic.filter.at('my.product.status', 'agenda'),
          prismic.filter.dateAfter(
            'my.product.startdate',
            new Date().getTime()
          ),
          prismic.filter.any('my.product.context', [
            'Tous',
            this.$route.params.context &&
            PRISMIC_CONTEXT[this.$route.params.context]
              ? PRISMIC_CONTEXT[this.$route.params.context]
              : PRISMIC_CONTEXT.h,
          ]),
        ],
        graphQuery: `{
            product {
              product_name
              image
              image_cdn
              startdate
            }
          }`,
        pageSize: 1,
        page: 1,
        lang: this.$i18n.locale,
        orderings: [
          {
            field: 'my.product.startdate',
            direction: 'asc',
          },
        ],
      })

      if (nextHighlightedProduct.results.length > 0) {
        this.nextHighlightedProduct = nextHighlightedProduct.results[0]
      }
    },
    loadContentImageFromLink(link) {
      if (link.type === 'collection' && link.data && link.data.image) {
        this.links[link.uid] = link
      }
    },
  },
}
</script>

<style lang="scss">
.footer {
  display: flex;
  flex-direction: column;

  &__newsletter {
    background: var(--secondary-color);
    color: var(--tertiary-color);

    .h5 {
      color: var(--tertiary-color);
    }

    &__title {
      font-size: var(--h2-font-size);
      color: var(--tertiary-color);

      @include mq($from: tablet) {
        font-size: var(--h1-font-size);
      }
    }

    .newsletter-footer {
      width: 100%;
    }

    .input {
      margin-bottom: 0;
      flex-grow: 1;

      &--checkbox {
        max-width: none;
        flex-grow: 0;

        input:checked + label::before {
          background-color: var(--tertiary-color);
        }

        label {
          padding-left: calc(var(--spacing) * 1.5);

          &::before {
            background: var(--tertiary-color);
            border-color: var(--tertiary-color);
          }

          &::after {
            color: var(--secondary-color);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23002e5d' d='M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .input__container {
      border-bottom: 0;
    }

    .action {
      box-shadow: none;

      @include mq($until: tablet) {
        width: 100%;
        margin: var(--spacing) auto 0;
      }
    }

    .form__elements {
      flex-grow: 1;
      max-width: rem(460px);

      @include mq($until: tablet) {
        display: flex;
        flex-direction: column;

        &__checkboxes {
          order: 1;
        }

        > .input {
          order: 2;
        }
      }
    }
  }

  &__prefooter-links {
    padding: calc(var(--spacing) * 0.2) 0;

    .content {
      gap: calc(var(--spacing) * 0.2);
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }
    }

    .skeleton {
      --width: 100vw;
      --height: calc(var(--width) * 395 / 720);

      @include mq($from: tablet) {
        --width: calc(100vw / 2 - var(--spacing) * 0.2);
      }

      height: var(--height);
    }
  }

  &__reinsurance {
    @include mq($until: tablet) {
      padding: rem($spacing) 0;
    }

    .h4 {
      position: relative;
      padding: rem($spacing);

      @include mq($until: tablet) {
        text-align: center;

        span {
          position: relative;
          display: inline-block;
          padding: 0 rem($spacing + 16px);
        }
      }

      @include mq($from: tablet) {
        padding: rem($spacing * 1.5) rem($spacing * 4);
        margin-left: rem(16px);

        &:last-child {
          margin-left: 0;

          .icon {
            display: none;
          }
        }
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      width: rem(16px);

      @include mq($from: tablet) {
        right: 0;
        transform: translate(50%, -50%);

        &--before {
          display: none;
        }
      }

      @include mq($until: tablet) {
        transform: translateY(-50%);

        &--before {
          left: 0;
        }

        &--after {
          right: 0;
        }
      }
    }
  }

  &__payments {
    background: var(--secondary-color-5);
  }

  &__nav {
    text-align: center;

    @include mq($until: tablet) {
      &:nth-child(2) {
        > a:last-child {
          padding-bottom: rem($spacing * 0.5);
        }
      }

      &:nth-child(3) {
        > a:first-child {
          padding-top: rem($spacing * 0.5);
        }
      }
    }

    @include mq($from: tablet) {
      order: 3;
      margin-top: rem(-$spacing * 0.5);

      &:nth-child(2) {
        order: 1;
      }
    }

    a {
      display: block;
      letter-spacing: 0.02em;
      padding: rem($spacing * 0.5) 0;
      line-height: 1;
      font-size: rem($btn-primary-desktop-fs);

      span::after {
        bottom: rem(-3px);
      }

      @include mq($from: tablet) {
        font-size: var(--base-font-size);
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &__socials {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(20px + $spacing);
      padding: 0 rem($spacing * 0.5);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .home & {
    .footer__prefooter-links {
      order: -1;
    }
  }
}

footer {
  .logo {
    .icon {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq($from: tablet) {
      order: 2;
    }

    @include mq($until: tablet) {
      .icon {
        width: rem(80px);
        height: rem(80px);
      }
    }
  }
}
</style>
