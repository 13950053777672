var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item--upsell"},[_c('div',{staticClass:"row row-no-wrap"},[_c('div',{staticClass:"cart-item__image"},[(_vm.loading)?_c('div',{staticClass:"skeleton",staticStyle:{"width":"86px","height":"86px"}},[_vm._v("\n         \n      ")]):(_vm.image)?_c('img',{attrs:{"src":_vm.image,"alt":""}}):_vm._e()]),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"text-label text-tag skeleton mb-0-4",staticStyle:{"width":"80px"}},[_vm._v("\n         \n      ")]),_vm._v(" "),_c('div',{staticClass:"text-small text-tag skeleton skeleton--bar",staticStyle:{"width":"120px"}},[_vm._v("\n         \n      ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-end justify-space-between"},[_vm._m(0),_vm._v(" "),_c('div',[_c('primary-button',{staticStyle:{"width":"100px"},attrs:{"text":"","aspect":"yellow","size":"secondary","skeleton":""}})],1)])]):_vm._e(),_vm._v(" "),(_vm.upsell.items[_vm.item.id] && _vm.link)?_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-label text-tag text-uppercase w-600 mb-0-4"},[_vm._v("\n          "+_vm._s(_vm.upsellTag)+"\n        ")])]),_vm._v(" "),(_vm.upsell.items[_vm.item.id].title)?_c('p',{staticClass:"text-small w-600 mb-0"},[_c('nuxt-link',{staticClass:"cart-item__link",attrs:{"to":{ path: _vm.link, query: { upsell: true } }},nativeOn:{"click":function($event){return _vm.$nuxt.$emit(_vm.EVENTS.CLOSE_CART)}}},[_vm._v(_vm._s(_vm.upsell.items[_vm.item.id].title))])],1):_vm._e(),_vm._v(" "),(_vm.numberOfColors > 0)?_c('p',{staticClass:"text-label text-color-secondary-2 mb-0-4 d-md-none"},[_vm._v("\n        "+_vm._s(_vm.$tc('cart.upsell.info', _vm.numberOfColors, {
            number: _vm.numberOfColors,
          }))+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-center align-md-end justify-space-between"},[_c('div',[(_vm.numberOfColors > 0)?_c('p',{staticClass:"text-label text-color-secondary-2 mb-0-4 d-none d-md-block"},[_vm._v("\n            "+_vm._s(_vm.$tc('cart.upsell.info', _vm.numberOfColors, {
                number: _vm.numberOfColors,
              }))+"\n          ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"cart-item__price text-secondary w-700 mb-0"},[_vm._v("\n            "+_vm._s(_vm.$n(
                _vm.upsell.items[_vm.item.id].priceRange.minVariantPrice.amount,
                'currency'
              ))+"\n          ")])]),_vm._v(" "),_c('div',[_c('primary-button',{attrs:{"text":_vm.buttonText,"aspect":"yellow","align":"center","size":"secondary","loading":_vm.buttonLoading,"disabled":_vm.inCart},on:{"click":_vm.click}})],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-label skeleton skeleton--bar mb-0-4",staticStyle:{"width":"100px"}},[_vm._v("\n             \n          ")]),_vm._v(" "),_c('div',{staticClass:"cart-item__price skeleton skeleton--bar",staticStyle:{"width":"40px"}},[_vm._v("\n             \n          ")])])
}]

export { render, staticRenderFns }