<template>
  <div class="cart-item--upsell">
    <div class="row row-no-wrap">
      <div class="cart-item__image">
        <div v-if="loading" class="skeleton" style="width: 86px; height: 86px">
          &nbsp;
        </div>
        <img v-else-if="image" :src="image" alt="" />
      </div>

      <div v-if="loading" class="flex-grow-1">
        <div class="text-label text-tag skeleton mb-0-4" style="width: 80px">
          &nbsp;
        </div>
        <div
          class="text-small text-tag skeleton skeleton--bar"
          style="width: 120px"
        >
          &nbsp;
        </div>
        <div class="d-flex align-end justify-space-between">
          <div>
            <div
              class="text-label skeleton skeleton--bar mb-0-4"
              style="width: 100px"
            >
              &nbsp;
            </div>
            <div
              class="cart-item__price skeleton skeleton--bar"
              style="width: 40px"
            >
              &nbsp;
            </div>
          </div>

          <div>
            <primary-button
              text=""
              aspect="yellow"
              size="secondary"
              skeleton
              style="width: 100px"
            />
          </div>
        </div>
      </div>
      <div v-if="upsell.items[item.id] && link" class="flex-grow-1">
        <div class="d-flex">
          <div class="text-label text-tag text-uppercase w-600 mb-0-4">
            {{ upsellTag }}
          </div>
        </div>
        <p v-if="upsell.items[item.id].title" class="text-small w-600 mb-0">
          <nuxt-link
            :to="{ path: link, query: { upsell: true } }"
            class="cart-item__link"
            @click.native="$nuxt.$emit(EVENTS.CLOSE_CART)"
            >{{ upsell.items[item.id].title }}</nuxt-link
          >
        </p>

        <p
          v-if="numberOfColors > 0"
          class="text-label text-color-secondary-2 mb-0-4 d-md-none"
        >
          {{
            $tc('cart.upsell.info', numberOfColors, {
              number: numberOfColors,
            })
          }}
        </p>

        <div class="d-flex align-center align-md-end justify-space-between">
          <div>
            <p
              v-if="numberOfColors > 0"
              class="text-label text-color-secondary-2 mb-0-4 d-none d-md-block"
            >
              {{
                $tc('cart.upsell.info', numberOfColors, {
                  number: numberOfColors,
                })
              }}
            </p>

            <p class="cart-item__price text-secondary w-700 mb-0">
              {{
                $n(
                  upsell.items[item.id].priceRange.minVariantPrice.amount,
                  'currency'
                )
              }}
            </p>
          </div>

          <div>
            <primary-button
              :text="buttonText"
              aspect="yellow"
              align="center"
              size="secondary"
              :loading="buttonLoading"
              :disabled="inCart"
              @click="click"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CartItem from '@/entities/cart/CartItem'

import PrimaryButton from '@/components/buttons/Primary'

import {
  CURRENCY,
  UPSELLING_TYPES,
  UPSELLING_TRADS,
  SS_UPSELLS_ITEMS,
} from '@/const'
import { EVENTS } from '@/const/events'
import ShopifyBase64 from '@/utils/shopifyBase64'

export default {
  name: 'CartUpSellItem',
  components: { PrimaryButton },
  props: {
    item: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    buttonLoading: false,
    EVENTS,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      upsell: 'cart/upsell',
    }),
    link() {
      if (this.item.slug[this.$i18n.locale.substr(0, 2)]) {
        return this.$contextPrismicPath({
          type: 'product',
          uid: this.item.slug[this.$i18n.locale.substr(0, 2)],
        })
      }

      return null
    },
    group() {
      return process.env.UPSELLING_GROUPS.sort((a, b) => {
        if (a.context === this.$route.params.context) {
          return -1
        }

        if (b.context === this.$route.params.context) {
          return 1
        }

        return a.weight - b.weight
      }).find((g) => {
        return (
          (g.type === UPSELLING_TYPES.LINK && g.target.id === this.item.id) ||
          (g.type === UPSELLING_TYPES.PRODUCTS &&
            g.products.some((p) => p.id === this.item.id))
        )
      })
    },
    numberOfColors() {
      if (this.upsell.items[this.item.id]) {
        const colors = this.upsell.items[this.item.id].options.find(
          (o) => o.name === 'Color'
        )
        return colors ? colors.values.length : 0
      }
      return 0
    },
    image() {
      if (
        this.upsell.items[this.item.id] &&
        this.upsell.items[this.item.id].images.edges.length > 0
      ) {
        return this.upsell.items[this.item.id].images.edges[0].node.src
      }

      return null
    },
    inCart() {
      if (this.item.id) {
        return this.cart.items.some(
          (i) => i.productId === ShopifyBase64.getId(this.item.id)
        )
      }
      return false
    },
    buttonText() {
      if (this.group.type === UPSELLING_TYPES.LINK) {
        if (this.inCart) {
          return this.$t('action.added')
        }

        return this.$t('action.add')
      }

      return this.$t('cart.upsell.action')
    },
    tagValue() {
      if (this.group.type === UPSELLING_TYPES.LINK) {
        return 'link'
      }

      if (UPSELLING_TRADS.includes(this.group.name)) {
        return this.group.name
      }

      return 'default'
    },
    upsellTag() {
      if (this.group.tag[this.$i18n.locale.substr(0, 2)]) {
        return this.group.tag[this.$i18n.locale.substr(0, 2)]
      }
      if (
        this.group.context &&
        this.upsell.group &&
        this.group.context !== this.upsell.group.context
      ) {
        return this.$t(`cart.upsell.${this.group.context}.${this.tagValue}`)
      }

      return this.$t(`cart.upsell.${this.tagValue}`)
    },
  },
  methods: {
    ...mapActions({
      addItemToCart: 'cart/addItems',
    }),
    async click(ev) {
      if (this.group.type === UPSELLING_TYPES.LINK) {
        this.addtoCart()
      } else {
        this.buttonLoading = true
        this.$emit('link', ev)
        this.buttonLoading = false

        this.$track.click('upsell-click', {
          upsell_id: this.item.id,
        })

        let upsells = window.sessionStorage.getItem(SS_UPSELLS_ITEMS)
        if (upsells) {
          upsells = JSON.parse(upsells)
        } else {
          upsells = []
        }

        upsells.push(this.item.id)
        window.sessionStorage.setItem(SS_UPSELLS_ITEMS, JSON.stringify(upsells))

        await this.$router.push({ path: this.link, query: { upsell: true } })
        this.$nuxt.$emit(EVENTS.CLOSE_CART)
      }
    },
    addtoCart() {
      this.buttonLoading = true
      const item = this.upsell.items[this.item.id]
      const cartItem = new CartItem({
        variant: {
          id: item.variants.edges[0].node.id,
        },
        quantity: 1,
        customAttributes: [
          {
            key: '_slug',
            value: this.item.slug[this.$i18n.locale.substr(0, 2)],
          },
        ],
      })
      this.addItemToCart([cartItem])
        .then((_) => {
          this.$track.addToCart(
            item.title,
            item.variants.edges[0].node,
            CURRENCY.EUR,
            this.cart
          )
        })
        .finally((_) => {
          this.buttonLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.cart-item--upsell {
  background: white;
  padding: rem($spacing * 0.8);

  .cart-item__image {
    position: relative;
    overflow: hidden;
    width: rem(80px);
    padding: 0;
    margin-right: var(--spacing);

    img {
      position: absolute;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cart-item__price {
    line-height: 1;
  }
}
</style>
